import React, {useCallback} from "react";
import { Card, Form, Input, Button, Alert, Row } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {useIntl} from "react-intl";

import s from "./style.module.css";
import {authStore} from "../../store/auth.store";
import {CONFIG_LS_KEY} from "../../constant/key";
import {useLocalStorage} from "react-use";
import {AuthConfig} from "../../model/auth.model";
import {root} from "../../config/router/authorizedRouter";
import {Link} from "react-router-dom";
import {restore} from "../../config/router/unauthorizedRouter";

const LoginPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const [config, setConfig] = useLocalStorage<AuthConfig | null>(
      CONFIG_LS_KEY,
      null,
      { raw: true }
  );
  const logIn = authStore(state => state.logIn);
  const configError = authStore(state => state.error.config);
  const configLoading = authStore(state => state.loading.config);

  const setTokenCallback = useCallback((newConfig: AuthConfig) => {
    newConfig.token !== config?.token && setConfig(newConfig);
    window.location.pathname = root;
  }, [config?.token, setConfig]);
  const onFinishCallback = useCallback(() => logIn(form.getFieldValue('username'), form.getFieldValue('password'), setTokenCallback), [form, logIn, setTokenCallback]);

  return (
    <Card className={s.LoginPage}>
      <Form form={form} onFinish={onFinishCallback}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: formatMessage({ id: 'ENTER_USERNAME' }) }]}
        >
          <Input
            placeholder={formatMessage({ id: 'USER_NAME' })}
            prefix={<UserOutlined />}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: formatMessage({ id: 'ENTER_PASSWORD' }) }]}
        >
          <Input.Password
            placeholder={formatMessage({ id: 'PASSWORD' })}
            prefix={<LockOutlined />}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={configLoading} block>
            {formatMessage({ id: 'SIGN_IN' })}
          </Button>
        </Form.Item>

        {configError && (
          <Alert
            message={configError.message}
            type="error" closable
          />
        )}

        <Row justify="end">
          <Button type="link" htmlType="button">
            <Link to={restore}>{formatMessage({ id: 'FORGOT_PASSWORD' })}</Link>
          </Button>
        </Row>
      </Form>
    </Card>
  );
};

export default LoginPage;
