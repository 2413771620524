export const downloadFile = (data: any): void => {
  const url = window.URL.createObjectURL(new Blob([data]));

  // Create a link element and click it to download the file
  const link = document.createElement('a');
  link.href = url;
  link.download = 'report.csv';
  document.body.appendChild(link);
  link.click();

  // Clean up the URL and link element
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};
