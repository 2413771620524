import {Card, Col, Empty, Modal, Row, Statistic} from "antd";
import { useIntl } from "react-intl"
import { useForm } from "antd/es/form/Form";
import React, { useCallback, useEffect, useState } from "react";

import ActivityBars from "./components/ActivityBars/ActivityBars";
import CreateActivityForm from "./components/CreateActivityForm/CreateActivityForm";
import { ActivityStatus } from "../../../enum/activity.enum";
import { activityStore } from "../../../store/activity.store";
import PageCard from "../../PageCard/PageCard";
import style from "./style.module.css";
import { RecordsActions } from "./components/RecordsActions/RecordsActions";
import { RecordsHistory } from "./components/RecordsHistory/RecordsHistory";
import { ViewSwitch, getViewSwitchOptions } from "./components/ViewSwitch/ViewSwitch";
import ActivityPie from "./components/ActivityPie/ActivityPie";

const RecordsPage = () => {
  const { formatMessage } = useIntl();
  const getIntervals = activityStore(state => state.getIntervalsStartPage);
  const getStatistics = activityStore(state => state.getStatistics);
  const setActivityToCreate = activityStore(state => state.setActivityToCreate);
  const daysIntervals = activityStore(state => state.daysIntervals || []);
  const activityToCreate = activityStore(state => state.activityToCreate);
  const getIntervalsLoading = activityStore(state => state.loading.getIntervalsStartPage);
  const getStatisticsLoading = activityStore(state => state.loading.getStatistics);
  const createActivityLoading = activityStore(state => state.loading.createActivity);
  const activitiesLength = activityStore(state => state.activities?.filter(activity => activity.status === ActivityStatus.RUNNING).length || 0);
  const { from, to, types, groupBy, tags} = activityStore(state => state.intervalsParams);

  const viewOptions = getViewSwitchOptions(formatMessage);

  const [form] = useForm();

  const [view, setView] = useState<string>(viewOptions[0].key);

  const onOkHandler = useCallback(() => form.validateFields().then(form.submit, console.error), [form]);

  const cancelHandler = useCallback(() => setActivityToCreate(null), [setActivityToCreate]);

  const isDataExist = !!daysIntervals?.length;

  useEffect(() => {
    !getIntervalsLoading && getIntervals();
    !getStatisticsLoading && getStatistics();
  }, [from, to, types, activitiesLength, groupBy, tags]);

  return <>
    <RecordsActions />
    {/*<Card title="Statistics" style={{marginBottom: '10px'}}>*/}
    {/*  <Row>*/}
    {/*    <Col span={8}>*/}
    {/*      <Statistic title="Today" value={112893} />*/}
    {/*    </Col>*/}
    {/*    <Col span={8}>*/}
    {/*      <Statistic title="This week" value={112893}/>*/}
    {/*    </Col>*/}
    {/*    <Col span={8}>*/}
    {/*      <Statistic title="This month" value={112893}/>*/}
    {/*    </Col>*/}
    {/*  </Row>*/}
    {/*</Card>*/}
    <PageCard title={(
      <div className={style.RecordsTitle}>
        <ViewSwitch view={view} setView={setView}/>
      </div>
    )}>
      {view === viewOptions[1].key && (
        <div
          className={style.ChartsContainer}
        >
          {isDataExist ? <ActivityBars/> : <Empty/>}
        </div>
      )}
      {view === viewOptions[2].key && (
        <div
          className={style.ChartsContainer}
        >
          {isDataExist ? <ActivityPie /> : <Empty/>}
        </div>
      )}
      {view === viewOptions[0].key && <RecordsHistory />}
      <Modal
        title={<h3>{formatMessage({ id: 'CREATE_ACTIVITY' })}</h3>}
        open={!!activityToCreate}
        onOk={onOkHandler}
        onCancel={cancelHandler}
        okText={formatMessage({ id: 'SAVE' })}
        confirmLoading={createActivityLoading}
      >
        {!!activityToCreate && <CreateActivityForm activity={activityToCreate} form={form} />}
      </Modal>
    </PageCard>
  </>
}

export default RecordsPage;
