import React, {useEffect} from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {useLocalStorage} from "react-use";
import {IntlProvider} from "react-intl";

import {changePassword, login, restore} from "./config/router/unauthorizedRouter";
import {authStore} from "./store/auth.store";
import {CONFIG_LS_KEY} from "./constant/key";
import {setJWT} from "./config/axiosConfig";
import {reports, root, settings} from "./config/router/authorizedRouter";
import AuthoredProvider from "./components/AuthoredProvider/AuthoredProvider";
import LoadingPage from "./components/LoadingPage/LoadingPage";
import RecordsPage from "./components/pages/RecordsPage/RecordsPage";
import LoginPage from "./components/LoginPage/LoginPage";
import Settings from "./components/pages/Setting/Settings";
import languages from "./resources";
import {language} from "./enum/languages.enum";
import {App, ConfigProvider} from "antd";
import PusherComponent from "./components/PusherComponent";
import RestorePage from "./components/RestorePage/RestorePage";
import ChangePasswordPage from "./components/ChangePasswordPage/ChangePasswordPage";

function MyApp() {
  const locale = authStore(state => state.user?.language || language.en);
  const getCurrentUser = authStore(state => state.getCurrentUser);
  const user = authStore(state => state.user);
  const userLoading = authStore(state => state.loading.user);
  const config = authStore(state => state.config);

  const [storageConfig] = useLocalStorage<string | null>(
      CONFIG_LS_KEY,
      null,
      { raw: true }
  );

  useEffect(() => {
    if (storageConfig) {
      setJWT(JSON.parse(storageConfig).token)
    }

    void getCurrentUser();
  }, [getCurrentUser, storageConfig, config]);

  if (userLoading) {
    return <LoadingPage/>
  }

  if (user) {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#523d5c',
            borderRadius: 5,
            fontFamily: 'Inter',
          },

          components: {

            Menu: {
              darkItemSelectedBg: '#523d5c',
              darkItemBg: '#2d1b37',
              itemBorderRadius: 0,
              // colorPrimaryBg: '#ff0000',
              itemMarginInline: 0,
              itemMarginBlock: 0,
            },
            DatePicker: {
              activeShadow: '0 0 0 1px rgba(5, 145, 255, 0.1)',
              cellActiveWithRangeBg: '#dddddd',
            },
            Select: {

            },
            Tabs: {
              itemSelectedColor: '#523d5c'
            }
          }
        }}
      >
        <App>
          <PusherComponent storageConfig={storageConfig}/>
          <AuthoredProvider>
            <Switch>
              <Route path={reports} component={RecordsPage} />
              <Route path={settings} component={Settings} />
              <Route path={root}>
                <Redirect to={reports}/>
              </Route>
            </Switch>
          </AuthoredProvider>
        </App>
      </ConfigProvider>
    )
  }

  return (
    <IntlProvider
      messages={languages[locale]}
      locale={language[locale]}
    >
      <Switch>
        <Route path={login} component={LoginPage}/>
        <Route path={restore} component={RestorePage}/>
        <Route path={changePassword} component={ChangePasswordPage}/>
        <Route path={root} >
          <Redirect to={login} />
        </Route>
      </Switch>
    </IntlProvider>
  );
}

export default MyApp;
