import {DatePicker, Form, FormInstance, Input, Radio, Select, TreeSelect} from "antd";
import FormItem from "antd/es/form/FormItem";
import dayjs from "dayjs";
import {useIntl} from "react-intl";
import React, {FC, useCallback, useEffect, useState} from "react";

import {activityStore} from "../../../../../store/activity.store";
import {Activity, Interval} from "../../../../../model/activity.model";
import {ActivityStatus} from "../../../../../enum/activity.enum";
import {DATE_TIME_FORMAT} from "../../../../../constant/format";
import {getActivityTypeOptions} from "../../../../AuthoredProvider/components/AuthoredHeader/helper";
import Intervals from "./components/Intervals";
import style from "./style.module.css";
import {debounce} from "../../../../../store/utils/debounce";

const { SHOW_PARENT } = TreeSelect;

interface IActivityForm {
  activity: Partial<Activity>
  form: FormInstance
}

const CreateActivityForm: FC<IActivityForm> = ({ activity, form }) => {
  const { formatMessage } = useIntl();
  const availableActivityTags = activityStore(state => state.activityTags);
  const activitiesTypesLoading = activityStore(state => state.loading.getActivityTypes);
  const activityTypes = activityStore(state => state.activityTypes || []);
  const createActivity = activityStore(state => state.createActivity);

  const [intervals, setIntervals] = useState<Interval[]>([]);
  const [activityType, setActivityType] = useState<string | undefined>();
  const [activityTags, setActivityTags] = useState<string[] | null>(null);
  const [isStartFieldDisabled, setIsStartFieldDisabled] = useState<boolean>();

  const getTagsStartPage = activityStore(state => state.getActivityTagsStartPage);
  const tagsLoading = activityStore(state => state.loading.getActivityTagsStartPage);
  const setActivityTagsParams = activityStore(state => state.setActivityTagsParams);
  const query = activityStore(state => state.activityTagsParams.query);

  useEffect(() => {
    !tagsLoading && getTagsStartPage();
  }, [query]);

  useEffect(() => {
    // default values
    form.setFieldsValue({
      ...activity,
      start: activity.start && dayjs(activity.start)
    })

    setIsStartFieldDisabled(activity.status !== ActivityStatus.RUNNING);
    activity.intervals && setIntervals(activity.intervals);
  }, [form, activity]);

  const onSearchCallback = useCallback(debounce((value: string) =>
    setActivityTagsParams({ query: value }), 500), [setActivityTagsParams]
  );

  const onActivitySelectChangeHandler = useCallback((option: string) => setActivityType(option), []);

  const onTagsSelectChangeHandler = useCallback((options: string[]) => setActivityTags(options), []);

  const handleFinish = useCallback((values: Activity) => createActivity(values), [createActivity]);

  const filterTreeNodeCallback = useCallback((inputValue: string, treeNode: any) => !inputValue
    ? true
    : treeNode.name.toLowerCase().includes(inputValue.toLowerCase()
    ), []);

  const onRadioChangeCallback = useCallback(() => {
    const isActivityStatusPausedOrStopped = activity.status === ActivityStatus.STOPPED || activity.status === ActivityStatus.PAUSED;
    const isActivityFormStatusValuePausedOrStopped = form.getFieldValue('status') === ActivityStatus.PAUSED || form.getFieldValue('status') === ActivityStatus.STOPPED;

    setIsStartFieldDisabled(isActivityFormStatusValuePausedOrStopped && activity.status === ActivityStatus.RUNNING);

    if (isActivityStatusPausedOrStopped && form.getFieldValue('status') === ActivityStatus.RUNNING) {
      form.setFieldValue('start', dayjs());
    }

    if (
      isActivityStatusPausedOrStopped && isActivityFormStatusValuePausedOrStopped) {
      setIsStartFieldDisabled(true);
      form.setFieldValue('start', undefined);
    }
  }, [activity, form])

  return (
    <Form form={form} onFinish={handleFinish}
          labelCol={{ span: 6 }}  wrapperCol={{ span: 18 }}
    >

      <FormItem
        label={formatMessage({ id: 'STATUS' })}
        name="status"
      >
        <Radio.Group onChange={onRadioChangeCallback}>
          <Radio.Button value={ActivityStatus.PAUSED}>{formatMessage({ id: ActivityStatus.PAUSED })}</Radio.Button>
          <Radio.Button value={ActivityStatus.RUNNING}>{formatMessage({ id: ActivityStatus.RUNNING })}</Radio.Button>
          <Radio.Button value={ActivityStatus.STOPPED}>{formatMessage({ id: ActivityStatus.STOPPED })}</Radio.Button>
        </Radio.Group>
      </FormItem>

      <FormItem
        rules={[{ required: true }]}
        label={formatMessage({ id: 'TYPE_ID' })}
        name="typeId"
      >
        <TreeSelect
          className={style.select}
          filterTreeNode={filterTreeNodeCallback}
          loading={activitiesTypesLoading}
          showSearch
          showCheckedStrategy={SHOW_PARENT}
          onChange={onActivitySelectChangeHandler}
          value={activityType}
          treeData={getActivityTypeOptions(activityTypes)}
          placeholder={formatMessage({ id: 'SELECT_ACTIVITY' })}
        />
      </FormItem>
      <FormItem
        label={formatMessage({ id: 'COMMENT' })}
        name="comment"
      >
        <Input />
      </FormItem>
      <FormItem
        style={{
          display: isStartFieldDisabled ? "none" : "block"
        }}
        label={formatMessage({ id: 'START' })}
        name="start"
      >
        <DatePicker
          disabled={isStartFieldDisabled}
          format={DATE_TIME_FORMAT}
          showTime
        />
      </FormItem>
      <FormItem
        label={formatMessage({ id: 'TAGS' })}
        name="tags"
      >
        <Select
          allowClear
          showSearch
          mode="tags"
          className={style.select}
          value={activityTags}
          onChange={onTagsSelectChangeHandler}
          onSearch={onSearchCallback}
          options={availableActivityTags?.map(tag => ({ value: tag, label: tag }))}
          loading={activitiesTypesLoading}
          placeholder={formatMessage({ id: 'SELECT_TAGS' })}
        />
      </FormItem>

      <Intervals
        activity={activity as Activity}
        intervals={intervals}
        form={form}
        setIntervals={setIntervals} />
    </Form>
  )
}

export default CreateActivityForm;
