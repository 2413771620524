import React from "react";
import UserForm from "./components/UserForm";

const Settings = () => {
  return (
      <UserForm/>
  )
}

export default Settings
