import {Button, DatePicker, TimeRangePickerProps} from "antd";
import {RangePickerProps} from "antd/es/date-picker";
import dayjs, {Dayjs} from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import React, {useMemo, useState} from "react";
import {useIntl} from "react-intl";

import {activityStore} from "../../../../../store/activity.store";
import {DATE_FORMAT, PICKER_DATE_FORMAT} from "../../../../../constant/format";
import style from "./style.module.css";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);

type RangeType = 'day' | 'week' | 'month' | 'custom';

interface DateRange {
  start: Dayjs;
  end: Dayjs;
}

interface PreviousNextRange {
  previous: DateRange;
  next: DateRange;
}

function determineRangeType(startDate: Dayjs, endDate: Dayjs): RangeType {
  if (startDate.isSame(endDate, 'day')) {
    return 'day';
  } else if (
    startDate.isSame(startDate.startOf('week'), 'day') &&
    endDate.isSame(endDate.endOf('week'), 'day')
  ) {
    return 'week';
  } else if (
    startDate.isSame(startDate.startOf('month'), 'day') &&
    endDate.isSame(endDate.endOf('month'), 'day')
  ) {
    return 'month';
  } else {
    return 'custom';
  }
}

function getPreviousNextRange(startDate: Dayjs, endDate: Dayjs): PreviousNextRange {
  let type = determineRangeType(startDate, endDate);
  switch (type) {
    case 'day':
      return {
        previous: {
          start: startDate.subtract(1, 'day'),
          end: endDate.subtract(1, 'day')
        },
        next: {
          start: startDate.add(1, 'day'),
          end: endDate.add(1, 'day')
        }
      };
    case 'week':
      return {
        previous: {
          start: startDate.subtract(1, 'week').startOf('week'),
          end: startDate.subtract(1, 'week').endOf('week')
        },
        next: {
          start: startDate.add(1, 'week').startOf('week'),
          end: startDate.add(1, 'week').endOf('week')
        }
      };
    case 'month':
      return {
        previous: {
          start: startDate.subtract(1, 'month').startOf('month'),
          end: startDate.subtract(1, 'month').endOf('month')
        },
        next: {
          start: startDate.add(1, 'month').startOf('month'),
          end: startDate.add(1, 'month').endOf('month')
        }
      };
    default:
      const diff = endDate.diff(startDate, 'day');
      return {
        previous: {
          start: startDate.subtract(diff + 1, 'day'),
          end: endDate.subtract(diff + 1, 'day')
        },
        next: {
          start: startDate.add(diff + 1, 'day'),
          end: endDate.add(diff + 1, 'day')
        }
      };
  }
}

const DateRangeFilter = () => {
  const { formatMessage } = useIntl();
  const from = activityStore(state => state.intervalsParams.from);
  const to = activityStore(state => state.intervalsParams.to);
  const setIntervalsParams = activityStore(state => state.setIntervalsParams);
  const [dateRange, setDateRange] = useState<RangePickerProps['value'] | null>([dayjs(from), dayjs(to)]);

  const handleDateChange = (dates: RangePickerProps['value']) => {
   setDateRange(dates);
   if (dates) {
     const [from, to]  = dates;
     setIntervalsParams({ from: from?.format(DATE_FORMAT), to: to?.format(DATE_FORMAT) })
   }
  };

  const handlePrev = () => {
    let start = dayjs(from);
    let finish = dayjs(to);
    let prevRange = getPreviousNextRange(start, finish).previous;

    setDateRange([prevRange.start, prevRange.end]);
    setIntervalsParams({
      from: prevRange.start.format(DATE_FORMAT),
      to: prevRange.end.format(DATE_FORMAT)
    });
  };

  const handleNext = () => {
    let start = dayjs(from);
    let finish = dayjs(to);
    let nextRange = getPreviousNextRange(start, finish).next;

    setDateRange([nextRange.start, nextRange.end]);
    setIntervalsParams({
      from: nextRange.start.format(DATE_FORMAT),
      to: nextRange.end.format(DATE_FORMAT)
    });
  };

  const rangePresets = useMemo<TimeRangePickerProps['presets']>(() => {
    const today = dayjs();

    return [
      { label: formatMessage({ id: 'TODAY' }), value: [today, today] },
      { label: formatMessage({ id: 'YESTERDAY' }), value: [today.subtract(1, 'day'), today.subtract(1, 'day')] },
      { label: formatMessage({ id: 'THIS_WEEK' }), value: [today.startOf('week'), today.endOf('week')] },
      { label: formatMessage({ id: 'LAST_WEEK' }), value: [today.startOf('week').subtract(1, 'week'), today.endOf('week').subtract(1, 'week')] },
      { label: formatMessage({ id: 'THIS_MONTH' }), value: [today.startOf('month'), today.endOf('month')] },
      { label: formatMessage({ id: 'LAST_MONTH' }), value: [today.subtract(1, 'month').startOf('month'), today.subtract(1, 'month').endOf('month')] },
    ]
  }, [formatMessage]);

  return (
    <div>
    <Button onClick={handlePrev}><LeftOutlined/></Button>
    <DatePicker.RangePicker
      allowClear={false}
      presets={rangePresets}
      value={dateRange}
      format={PICKER_DATE_FORMAT}
      style={{width: '260px'}}
      className={style.DateRangeFilter}
      onChange={handleDateChange}
    />
      <Button  onClick={handleNext}><RightOutlined/></Button>
    </div>
  )
}

export default DateRangeFilter;
