import React, {useCallback} from 'react';
import {Bar, Line} from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import {makeSummaryChartData, makeSummaryChartLineOptions, makeSummaryChartOptions} from "./helper";
import {activityStore} from "../../../../../store/activity.store";
import {Checkbox, Row, Segmented} from "antd";
import {GroupBy, TrendChartType} from "../../../../../enum/activity.enum";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {BarChartOutlined, LineChartOutlined} from "@ant-design/icons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement, Filler
);

const groupOptions  = [
  { label: 'Day', value:'day' }, { label: 'Week', value:'week' }, { label: 'Month', value:'month' }
]

const chartOptions  = [
  { value: TrendChartType.BAR, icon: <BarChartOutlined/> }, { value: TrendChartType.LINE, icon: <LineChartOutlined/>}
]

const ActivityBars = () => {
  const groupBy = activityStore(state => state.intervalsParams.groupBy);
  const statistics = activityStore(state => state.statistics);
  const statisticsParams = activityStore(state => state.statisticsParams);
  const setIntervalsParams = activityStore(state => state.setIntervalsParams);
  const setStatisticsParams = activityStore(state => state.setStatisticsParams);

  const onGroupByChange = useCallback((value: string | number) => setIntervalsParams({ groupBy: value as GroupBy }), [setIntervalsParams]);
  const onChartTypeChange = useCallback((value: TrendChartType) => setStatisticsParams({ trendChartType: value }),
    [setStatisticsParams]);
  const onGroupedChange = useCallback((value: CheckboxChangeEvent ) =>
    setStatisticsParams({ grouped: value.target.checked}), [setStatisticsParams]);

  if (!statistics) {
    return null
  }

  const data = makeSummaryChartData(statistics, statisticsParams.grouped);
  const bar = statisticsParams.trendChartType === TrendChartType.BAR
  const animated = true;
  const options = makeSummaryChartOptions(animated);
  const options2 = makeSummaryChartLineOptions(animated);

  return <div>
      <Checkbox style={{marginRight: '30px'}} onChange={onGroupedChange}>Grouped</Checkbox>
    Granularity:&nbsp; &nbsp;
      <Segmented style={{marginRight: '30px'}}  options={groupOptions} value={groupBy}
                 onChange={onGroupByChange}/>

      <Segmented options={chartOptions} value={statisticsParams.trendChartType} onChange={onChartTypeChange} />

    { bar ?
      <Row style={{marginTop: '20px'}}>
          <Bar
            style={{ minHeight: 250, maxHeight: 350, minWidth: '100%' }}
            options={options}
            data={data}
          />
      </Row>

    :
      <Row style={{marginTop: '20px'}}>
        <Line
          style={{ minHeight: 250, maxHeight: 350, minWidth: '100%' }}
          options={options2} data={data}/>
      </Row>
    }
  </div>
}

export default ActivityBars;
