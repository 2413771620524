import {AxiosError} from "axios";
import { create } from 'zustand';

import {AuthState, InitialAuthState, User} from "../model/auth.model";
import {AuthService} from "../api/auth.service";
import {clearJWT} from "../config/axiosConfig";

const initialState: InitialAuthState = {
  user: null,
  timezones: null,
  loading: {
    config: false,
    user: true,
    timezones: true,
    editUser: false
  },
  error: {
    config: null,
    user: null,
    timezones: null,
    editUser: null
  },
  successLogOut: false,
  channel: null,
  config: null
};

export const authStore = create<AuthState>((set, get) => ({
  ...initialState,

  logOut: () => {
    get().channel?.unbind_all()
    set({
      user: null,
      channel: null
    })

    clearJWT()
    window.location.pathname = '/'
    localStorage.clear()
  },

  editUser: async (user: User) => {
    try {
      set({
        loading: {
          ...get().loading,
          editUser: true
        }
      });

      const userRes = await AuthService.editUser(user)

        set({
          user: userRes.data,
          loading: {
            ...get().loading,
            editUser: false
          },
          error: {
            ...get().error,
            editUser: null
          },
        })
    } catch (err) {
      const error = err as AxiosError<Error>;

      set({
        loading: {
          ...get().loading,
          editUser: false
        },
        error: {
          ...get().error,
          editUser: error
        }
      })
    }
  },
  getTimezones: async () => {
    try {
      set({
        loading: {
          ...get().loading,
          timezones: true
        }
      })

      const timezonesRes = await AuthService.getTimezones();

      set({
        timezones: timezonesRes.data,
        loading: {
          ...get().loading,
          timezones: false
        },
        error: {
          ...get().error,
          timezones: null
        }
      })
    } catch (err) {
      const error = err as AxiosError<Error>

      set({
        config: null,
        loading: {
          ...get().loading,
          timezones: false
        },
        error: {
          ...get().error,
          timezones: error
        }
      })
    }
  },
  logIn: async (username: string, password:string, onSuccess) => {
    try {
      set({
        loading: {
          ...get().loading,
          config: true
        }
      })

      const loginRes = await AuthService.login(username, password);

      onSuccess(loginRes.data);

      set({
        config: loginRes.data,
        loading: {
          ...get().loading,
          config: false
        },
        error: {
          ...get().error,
          config: null
        }
      })
    } catch (err) {
      const error = err as AxiosError<Error>
      set({
        config: null,
        loading: {
          ...get().loading,
          config: false
        },
        error: {
          ...get().error,
          config: error
        }
      })
    }
  },
  getCurrentUser: async (onSuccess) => {
    try {
      set({
        loading: {
          ...get().loading,
          user: true
        },
      })

      const userRes = await AuthService.getCurrentUser();

      if (userRes.data) {
        set({
          user: userRes.data,
          loading: {
            ...get().loading,
            user: false
          },
          error: {
            ...get().error,
            user: null
          }
        })
      }

      if (onSuccess) {
        onSuccess()
      }
    } catch (err) {
      const error = err as AxiosError<Error>
      set({
        config: null,
        loading: {
          ...get().loading,
          user: false
        },
        error: {
          ...get().error,
          user: error
        }
      })
    }
  }
}));
