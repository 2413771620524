export enum ActivityStatus {
  PAUSED = "PAUSED",
  RUNNING = "RUNNING",
  STOPPED = "STOPPED"
}

export enum GroupBy {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month'
}

export enum ActionOnStart {
  NONE = "NONE",
  PAUSE = "PAUSE",
  STOP = "STOP"
}

export enum TrendChartType {
  BAR = 'bar',
  LINE = 'line'
}
