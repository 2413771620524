import React, {FC} from "react";
import dayjs from "dayjs";
import {faCirclePause, faCirclePlay, faCircleStop} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import convertDecimalToHexColor from "../../../../../utils/convertDecimalToHexColor";
import {ActivityStatus} from "../../../../../enum/activity.enum";
import {TIMER_FORMAT} from "../../../../../constant/format";
import Timer from "../../../../Timer/Timer";
import {Activity, ActivityType} from "../../../../../model/activity.model";
import {activityStore} from "../../../../../store/activity.store";
import style from "../style.module.css";
import {getActivityStartTime, getActivityType} from "./helper";

interface ICurrentActivity {
  compact?: true
  activityTypes: ActivityType[]
  activity: Activity
  restActivities?: Activity[]
}

const CurrentActivity: FC<ICurrentActivity> = ({ activityTypes, activity, compact }) => {

  const stopActivityLoading = activityStore(state => state.loading.stopActivity);
  const pauseActivityLoading = activityStore(state => state.loading.pauseActivity);
  const resumeActivityLoading = activityStore(state => state.loading.resumeActivity);
  const setActivityToEdit = activityStore(state => state.setActivityToEdit);
  const stopActivity = activityStore(state => state.stopActivity);
  const pauseActivity = activityStore(state => state.pauseActivity);
  const resumeActivity = activityStore(state => state.resumeActivity);

  const activityType = getActivityType(activityTypes, activity);

  const handleStopActivity = () => !stopActivityLoading && stopActivity(activity.id);
  const handlePauseActivity = () => !stopActivityLoading && pauseActivity(activity.id);
  const handleResumeActivity = () => !resumeActivityLoading && resumeActivity(activity.id);
  const setCurrentActivityToEdit = () => setActivityToEdit(activity);

  const timerStart = getActivityStartTime(activity);

  return (
    <div className={`${style.activity} ${compact ? style.compact : ''}`} key={activity.id}>
      <div
        style={{ background: activityType && convertDecimalToHexColor(activityType.color)}}
        className={style.ActivityTypesFilterLabelColor}
      />
      <div className={style.title}>
        <span onClick={setCurrentActivityToEdit}>{activityType?.name}</span>
      </div>
      <span className={style.timer}>
         {activity.status === ActivityStatus.PAUSED && dayjs.duration(activity.duration * 1000).format(TIMER_FORMAT)}
         {activity.status === ActivityStatus.RUNNING && <Timer start={timerStart}/>}
      </span>
      {
        activity.status === ActivityStatus.RUNNING
          ? <button
            className={style.actionButton}
            onClick={handlePauseActivity}
            disabled={pauseActivityLoading}
          >
            <FontAwesomeIcon icon={faCirclePause} />
          </button>
          : <button
            className={style.actionButton}
            onClick={handleResumeActivity}
            disabled={resumeActivityLoading}
          >
            <FontAwesomeIcon icon={faCirclePlay} />
          </button>
      }
      <button
        className={style.actionButton}
        onClick={handleStopActivity}
        disabled={stopActivityLoading}
      >
        <FontAwesomeIcon icon={faCircleStop} />
      </button>

    </div>
  )
}

export default CurrentActivity;
