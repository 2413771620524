import {Button, Card, Form, Input, Select} from "antd";
import FormItem from "antd/es/form/FormItem";
import {useForm} from "antd/es/form/Form";
import {useIntl} from "react-intl";
import {useCallback, useEffect, useState} from "react";

import {authStore} from "../../../../store/auth.store";
import {User} from "../../../../model/auth.model";
import {onStartActions} from "../../../../constant/settings";
import {ActionOnStart} from "../../../../enum/activity.enum";


const UserForm = () => {
  const [form] = useForm();
  const { formatMessage } = useIntl();
  const timezonesLoading = authStore(state => state.loading.timezones);
  const timezones = authStore(state => state.timezones);
  const getTimezones = authStore(state => state.getTimezones);
  const editUser = authStore(state => state.editUser);
  const user = authStore(state => state.user);
  const editUserLoading = authStore(state => state.loading.editUser);

  const [onStartAction, setOnStartAction] = useState<ActionOnStart>();

  // useEffect(() => {
  //   void getTimezones();
  // }, [getTimezones]);

  useEffect(() => {
    // default values
    form.setFieldsValue(user)
    setOnStartAction(user?.settings.actionOnStart);
  }, [form, user]);

  const handleFinish = useCallback((values: User) => editUser(values), [editUser]);

  const handleOnStartActionChange = useCallback((value: ActionOnStart) => {
    form.setFieldValue('settings', { ...form.getFieldValue('settings'), actionOnStart: value});
    setOnStartAction(value);
  }, [form]);

  return (
    <Card title={formatMessage({ id: 'SETTINGS' })}>
      <Form onFinish={handleFinish} form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}

      >
        <FormItem name="username" label={formatMessage({ id: 'USER_NAME' })}>
          <Input disabled/>
        </FormItem>
        <FormItem name="firstName" label={formatMessage({ id: 'FIRST_NAME' })}>
          <Input />
        </FormItem>
        <FormItem name="lastName" label={formatMessage({ id: 'LAST_NAME' })}>
          <Input />
        </FormItem>
        {/*<FormItem name="timeZone" label={formatMessage({ id: 'TIMEZONE' })}>*/}
        {/*  <Select*/}
        {/*    allowClear*/}
        {/*    loading={timezonesLoading}*/}
        {/*    placeholder={formatMessage({ id: 'SELECT_TIMEZONE' })}*/}
        {/*    showSearch*/}
        {/*    options={timezones?.map(timezone => ({ label: timezone, value: timezone }))}*/}
        {/*  />*/}
        {/*</FormItem>*/}
        {/*<FormItem name="language" label={formatMessage({ id: 'LANGUAGE' })}>*/}
        {/*  <Select*/}
        {/*    allowClear*/}
        {/*    placeholder={formatMessage({ id: 'SELECT_LANGUAGE' })}*/}
        {/*    options={languages.map(lang => ({ label: languageLabels[lang], value: lang }))}*/}
        {/*    showSearch*/}
        {/*  />*/}
        {/*</FormItem>*/}
        <FormItem name="settings" hidden/>
        <FormItem label={formatMessage({ id: 'ON_START_ACTION' })}>
          <Select
            value={onStartAction}
            onChange={handleOnStartActionChange}
            options={onStartActions.map(action => ({ label: formatMessage({ id: action }), value: action }))}
            showSearch
          />
        </FormItem>
        <Button
          loading={editUserLoading}
          htmlType="submit"
        >
          {formatMessage({ id: 'SAVE' })}
        </Button>
      </Form>
    </Card>
  );
};

export default UserForm;
