import React from "react";
import {ActivityType} from "../../../../../model/activity.model";
import ActivityTypeLabel from "../../../../ActivityTypeLabel/ActivityTypeLabel";

export interface TreeData {
  title: React.ReactNode,
  value: string,
  key: string,
  disabled?: boolean,
  name: string,
  children?: TreeData[] | undefined
}

export const getActivityTypeOptions = (activityTypes: ActivityType[], parentId?: string): TreeData[] => {
  return activityTypes
    ?.filter(activityType => !parentId ? !activityType.parentId : parentId === activityType.parentId)
    .map((activityType) => {
      const children = activityTypes.filter(child => child.parentId === activityType.id);
      return ({
        title: <ActivityTypeLabel activityType={activityType}/>,
        value: activityType.id,
        key: activityType.id,
        name: activityType.name,
        children: !!children.length ? children.map(child => (
            {
              title: <ActivityTypeLabel activityType={child}/>,
              value: child.id,
              key: child.id,
              name: child.name,
              children: getActivityTypeOptions(activityTypes, child.id)
            }
          )) : undefined
      })
    });
};
