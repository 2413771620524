import React, {useCallback} from "react";
import {Card, Form, Input, Button, Alert, Row} from "antd";
import { UserOutlined } from "@ant-design/icons";
import {useIntl} from "react-intl";

import s from "./style.module.css";
import {restoreStore} from "../../store/restore.store";
import {Link} from "react-router-dom";
import {login, restore} from "../../config/router/unauthorizedRouter";

const RestorePage: React.FC = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const restore = restoreStore(state => state.restore);
  const error = restoreStore(state => state.error);
  const message = restoreStore(state => state.message);
  const loading = restoreStore(state => state.loading);

  const onFinishCallback = useCallback(() => {
      return restore(form.getFieldValue('username'));
    }, [form, restore]
  );

  return (
    <Card className={s.RestorePage}>
      <Form form={form} onFinish={onFinishCallback}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: formatMessage({ id: 'ENTER_USERNAME' }) }]}
        >
          <Input
            placeholder={formatMessage({ id: 'USER_NAME' })}
            prefix={<UserOutlined />}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            {formatMessage({ id: 'RESTORE' })}
          </Button>
        </Form.Item>

        {message && (
          <Alert message={message} type="info" closable/>
        )}

        {error && (
          <Alert
            message={error.message}
            type="error" closable
          />
        )}

        <Row justify="end">
          <Button type="link" htmlType="button">
            <Link to={login}>{formatMessage({ id: 'SIGN_IN' })}</Link>
          </Button>
        </Row>

      </Form>
    </Card>
  );
};

export default RestorePage;
