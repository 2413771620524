import {TreeSelect} from "antd";
import React, {FC, useCallback, useState} from "react";
import {TreeData} from "../pages/RecordsPage/components/ActivityTypeFilter/helper";
import './style.css';

interface ICustomFilterSelect {
  allowClear?: boolean,
  showSearch?: boolean,
  loading?: boolean
  className: string,
  onChange: (value: string[]) => void,
  filterTreeNode?: (inputValue: string, treeNode: any) => boolean,
  value?: string[],
  treeData: TreeData[],
  placeholder: string,
  onSearch?: (...args: any[]) => void,
  label: string
}

const { SHOW_PARENT } = TreeSelect;

const CustomTreeSelect: FC<ICustomFilterSelect> = ({
                                                     allowClear,
                                                     showSearch,
                                                     className,
                                                     onChange,
                                                     loading,
                                                     filterTreeNode,
                                                     value,
                                                     treeData,
                                                     placeholder,
                                                     label,
                                                     onSearch
}) => {
  const [open, setOpen] = useState(false);
  const valuesNumber = value?.length;

  const toggleCallback = useCallback(() => setOpen(open => !open), []);

  return (
    <label className='CustomTreeSelect'>
      <div
        onClick={toggleCallback}
        className={`CustomTreeSelectIndicator${open ? ' open' : ''}`}
      >
        <span>{label}</span>
        {!!valuesNumber && (
          <span className="CustomTreeSelectCount">
            {valuesNumber}
          </span>
        )}
      </div>
      { open && <>
          <TreeSelect
            allowClear={allowClear}
            className={className}
            filterTreeNode={filterTreeNode}
            onChange={onChange}
            value={value}
            loading={loading}
            showSearch={showSearch}
            showCheckedStrategy={SHOW_PARENT}
            treeCheckable
            treeData={treeData}
            onBlur={toggleCallback}
            open={true}
            placeholder={placeholder}
            onSearch={onSearch}
          />
          <div className='CustomTreeSelectSeparator'/>
      </>
      }
    </label>
  )
}

export default CustomTreeSelect;
