import { Button } from "antd";
import React, { useCallback, useMemo } from 'react';
import style from "../../style.module.css";
import FiltersSelect from "../FiltersSelect/FiltersSelect";
import ActivityTypeFilter from "../ActivityTypeFilter/ActivityTypeFilter";
import ActivityTagsFilter from "../ActivityTagsFilter/ActivityTagsFilter";
import CreateFilterButton from "../CreateFilterButton/CreateFilterButton";
import DateRangeFilter from "../DateRangeFilter/DateRangeFilter";
import { activityStore } from "../../../../../store/activity.store";
import { IntervalFilter } from "../../../../../model/activity.model";

export const RecordsActions = () => {
  const updateFilter = activityStore(state => state.updateFilter);
  const filtersConfiguration = activityStore(state => state.filtersConfiguration);
  const { types: paramsTypes, tags: activityTags } = activityStore(state => state.intervalsParams);
  const { types, tags} = activityStore(state => state.intervalsParams);
  const intervalFilters = activityStore(state => state.intervalFilters);

  const chosenFilter = useMemo(() =>
      intervalFilters?.find(filter => filter.name === filtersConfiguration),
    [intervalFilters, filtersConfiguration]
  );

  const isFilterUpdated = useMemo(() => (chosenFilter?.types
        && JSON.stringify(types?.sort()) !== JSON.stringify(chosenFilter.types.sort()))
      ||
      (chosenFilter?.tags
        && JSON.stringify(tags?.sort()) !== JSON.stringify(chosenFilter.tags.sort())),
    [chosenFilter, tags, types]);

  const updateFilterCallback = useCallback(() => {
      if (chosenFilter) {
        const newFilter: IntervalFilter = {
          ...chosenFilter,
          types,
          tags
        };

        void updateFilter(newFilter);
      }
    },
    [chosenFilter, tags, types, updateFilter]
  );

  return <>
    <div className={style.RecordsPageActions}>
      <FiltersSelect />
      <ActivityTypeFilter />
      <ActivityTagsFilter />
      {(!filtersConfiguration && (!!paramsTypes?.length || !!activityTags?.length)) && <CreateFilterButton />}
      {isFilterUpdated && <Button onClick={updateFilterCallback}>Update</Button>}
    </div>
    <div className={style.RecordsPageActions}>
      <DateRangeFilter />
    </div>
  </>
}
