export const formattedDuration = (durationMs: number): string => {
  let hours   = Math.floor(durationMs  / 3600000);
  let minutes = Math.floor((durationMs / 1000 - (hours * 3600)) / 60);

  let hoursString: string
  let minuteString: string

  if (hours < 10) { hoursString = `0${hours}` } else {hoursString = `${hours}`}
  if (minutes < 10) { minuteString  = `0${minutes}`} else { minuteString = `${minutes}`}
  return `${hoursString}:${minuteString}`
}

export const convertHoursToMilliseconds = (hours: number): number => hours * 60 * 60 * 1000;

export const convertSecondsToHours = (seconds: number): number => seconds / 60 / 60;
