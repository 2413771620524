import style from "./style.module.css";
import convertDecimalToHexColor from "../../utils/convertDecimalToHexColor";
import {ActivityType} from "../../model/activity.model";
import React, {FC} from "react";

interface IActivityType {
  activityType: ActivityType
}
const ActivityTypeLabel: FC<IActivityType> = ({ activityType }) => (<div className={style.ActivityTypesFilterLabel}>
  <div
    style={{background: convertDecimalToHexColor(activityType.color)}}
    className={style.ActivityTypesFilterLabelColor}
  />
  {activityType.name}
</div>)


export default ActivityTypeLabel;
