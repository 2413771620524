const ru = {
  ACTIVITY: 'Активность',
  ADD_INTERVAL: 'Добавить Интервал',
  CREATE_ACTIVITY: 'Новая Активность',
  CREATE_FILTER_CONFIGURATION: 'Cоздать Конфигурацию фильтра',
  CONFIRM_FILTER_DELETE: 'Вы уверены что хотите удалить конфигарацию фильтров?',
  RECORDS: 'Записи',
  RUNNING: 'Активен',
  SAVE: 'Сохранить',
  SAVE_FILTER_CONFIGURATION: 'Сохранить',
  SELECT_TIMEZONE: 'Выберите Таймзону',
  SELECT_TYPES: 'Выберите Типы',
  SELECT_ACTIVITY: 'Выберите Активность',
  SELECT_TAGS: 'Выбрать Теги',
  SELECT_FILTER: 'Выбрать Фильтр',
  SETTINGS: 'Настройки',
  START_ACTIVITY: 'Начать',
  STATUS: 'Статус',
  STOPPED: 'Стоп',
  STOP: 'Остановить',
  START: 'Начало',
  SIGN_IN: 'Войти',
  SUMMARY: 'Выкладка',
  TIMEZONE: 'Таймзона',
  TOTAL: 'Всего',
  GROUPED: 'Сгруппирован',
  EDIT: 'Редактировать',
  EDIT_USER: 'Редактировать Пользователя',
  EDIT_ACTIVITY: 'Редактировать Активность',
  ENTER_USERNAME: 'Введите ваше имя',
  ENTER_PASSWORD: 'Введите ваш пароль',
  FILTER_NAME_PLACEHOLDER: 'Введите имя для конфигурации фильтров',
  PASSWORD: 'Пароль',
  REPEAT_PASSWORD: 'Повторите пароль',
  PAUSED: 'Пауза',
  PAUSE: 'Приостановить',
  FINISH: 'Конец',
  FORGOT_PASSWORD: 'Забыл Пароль',
  HISTORY: 'История',
  INTERVALS: 'Интервалы',
  COMMENT: 'Коментарий',
  DURATION: 'Длительность',
  DOWNLOAD_HISTORY: 'Экспорт',
  TO: 'По',
  TODAY: 'Сегодня',
  THIS_WEEK: 'Эта неделя',
  THIS_MONTH: 'Этот месяц',
  ID: 'id',
  TYPE_ID: 'Тип активности',
  TYPES: 'Типы',
  YES: 'Да',
  YESTERDAY: 'Вчера',
  NO: 'Нет',
  NONE: 'Ничего',
  DELETE: 'Удалить',
  DELETE_INTERVAL: 'Удалить Интервал',
  DELETE_INTERVAL_CONFIRM: 'Вы уверены, что хотите удалить интервал?',
  USER: 'Пользователь',
  FIRST_NAME: 'Имя',
  LAST_NAME: 'Фамилия',
  LAST_WEEK: 'Прошлая Неделя',
  LAST_MONTH: 'Прошлый месяц',
  LOAD_MORE: 'Загрузить Больше',
  USER_NAME: 'E-Mail',
  LANGUAGE: 'Язык',
  SELECT_LANGUAGE: 'Выберите Язык',
  ON_START_ACTION: 'Действие при старте',
  RESTORE: 'Восстановить',
  CHANGE_PASSWORD: 'Изменить пароль',
}

export default ru
