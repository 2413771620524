import React, {useCallback} from 'react';
import {useIntl} from "react-intl";

import {activityStore} from "../../../../../store/activity.store";
import {debounce} from "../../../../../store/utils/debounce";
import style from './style.module.css';
import CustomTreeSelect from "../../../../CustomFilterSelect/CustomFilterSelect";

const ActivityTagsFilter = () => {
  const { formatMessage } = useIntl();
  const activityTags = activityStore(state => state.intervalsParams.tags);
  const availableActivityTags = activityStore(state => state.activityTags);
  const getActivityTagsStartPage = activityStore(state => state.loading.getTagsStartPage);
  const setIntervalsParams = activityStore(state => state.setIntervalsParams);
  const setTagsParams = activityStore(state => state.setTagsParams);

  const onTagsSelectChangeHandlerCallback = useCallback((values: string[]) => setIntervalsParams({ tags: values || [] }), [setIntervalsParams]);

  const onSearchCallback = useCallback(debounce((value: string) => setTagsParams({ query: value }), 500), [setTagsParams]);

  return (
    <CustomTreeSelect
      allowClear
      showSearch
      className={style.ActivityTagsFilter}
      value={activityTags}
      onChange={onTagsSelectChangeHandlerCallback}
      treeData={availableActivityTags?.map(tag => ({ value: tag, label: tag, title: tag, key: tag, name: tag })) || []}
      loading={getActivityTagsStartPage}
      placeholder={formatMessage({ id: 'SELECT_TAGS' })}
      onSearch={onSearchCallback}
      label={formatMessage({ id: 'TAGS' })}
    />
  )
};

export default ActivityTagsFilter;
