import {DatePicker, Form, FormInstance, Input, Radio, Select, TreeSelect} from "antd";
import FormItem from "antd/es/form/FormItem";
import dayjs from "dayjs";
import {useIntl} from "react-intl";
import React, {FC, useCallback, useEffect, useState} from "react";

import style from "../AuthoredHeader/style.module.css";
import {activityStore} from "../../../../store/activity.store";
import {Activity, Interval} from "../../../../model/activity.model";
import {ActivityStatus} from "../../../../enum/activity.enum";
import {DATE_TIME_FORMAT} from "../../../../constant/format";
import {getActivityTypeOptions} from "../AuthoredHeader/helper";
import Intervals from "./components/Intervals";
import {debounce} from "../../../../store/utils/debounce";

const { SHOW_PARENT } = TreeSelect;

interface IActivityForm {
  activity: Activity
  form: FormInstance
}

const EditActivityForm: FC<IActivityForm> = ({ activity, form }) => {
  const { formatMessage } = useIntl();
  const availableActivityTags = activityStore(state => state.activityTags);
  const activitiesTypesLoading = activityStore(state => state.loading.getActivityTypes);
  const activityTypes = activityStore(state => state.activityTypes || []);
  const rewriteActivity = activityStore(state => state.rewriteActivity);

  const [intervals, setIntervals] = useState<Interval[]>([]);
  const [activityType, setActivityType] = useState<string | undefined>();
  const [activityTags, setActivityTags] = useState<string[] | null>(null);
  const [isStartFieldDisabled, setIsStartFieldDisabled] = useState<boolean>();
  const getTagsStartPage = activityStore(state => state.getActivityTagsStartPage);
  const tagsLoading = activityStore(state => state.loading.getActivityTagsStartPage);
  const setActivityTagsParams = activityStore(state => state.setActivityTagsParams);
  const query = activityStore(state => state.activityTagsParams.query);

  useEffect(() => {
    // default values
    form.setFieldsValue({
      ...activity,
      start: activity.start && dayjs(activity.start)
    })

    setIsStartFieldDisabled(activity.status !== ActivityStatus.RUNNING);
    setIntervals(activity.intervals);
  }, [form, activity]);

  useEffect(() => {
    !tagsLoading && getTagsStartPage();
  }, [query]);

  const onActivitySelectChangeHandler = useCallback((option: string) => setActivityType(option), []);

  const onTagsSelectChangeHandler = useCallback((options: string[]) => setActivityTags(options), []);

  const handleFinish = useCallback((values: Activity) => rewriteActivity(values), [rewriteActivity]);

  const filterTreeNodeCallback = useCallback((inputValue: string, treeNode: any) => !inputValue
    ? true
    : treeNode.name.toLowerCase().includes(inputValue.toLowerCase()
    ), []);

  const onSearchCallback = useCallback(debounce((value: string) =>
    setActivityTagsParams({ query: value }), 500), [setActivityTagsParams]
  );

  const onRadioChangeCallback = useCallback(() => {
    const isActivityStatusPausedOrStopped = activity.status === ActivityStatus.STOPPED || activity.status === ActivityStatus.PAUSED;
    const formStatus = form.getFieldValue('status');

    setIsStartFieldDisabled(false)

    if (isActivityStatusPausedOrStopped) {
      if (formStatus === ActivityStatus.RUNNING) {
        form.setFieldValue('start', dayjs());
        activity.start = new Date().toISOString();
      }
      else {
        setIsStartFieldDisabled(true);
        form.setFieldValue('start', undefined);
      }
      activity.status = formStatus
    }
    else {
      if (formStatus === ActivityStatus.PAUSED || formStatus === ActivityStatus.STOPPED) {
        setIsStartFieldDisabled(true);

        const newInterval = {
          typeId: activity.typeId,
          activityId: activity.id,
          tags: activity.tags,
          start: activity.start,
          finish: new Date().toISOString(),
          comment: '',
        } as Interval;

        activity.status = formStatus

        form.setFieldValue('start', undefined);

        setIntervals(intervals => {
          const newIntervals = [...intervals, newInterval];
          form.setFieldValue('intervals', newIntervals);

          return newIntervals;
        });
      }
    }

  }, [activity, form])

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
    >
      <FormItem
        label='ID'
        name="id"
        hidden
      >
        <Input disabled/>
      </FormItem>
      <FormItem
        label={formatMessage({ id: 'STATUS' })}
        name="status"
      >
        <Radio.Group onChange={onRadioChangeCallback}>
          <Radio.Button value={ActivityStatus.PAUSED}>{formatMessage({ id: ActivityStatus.PAUSED })}</Radio.Button>
          <Radio.Button value={ActivityStatus.RUNNING}>{formatMessage({ id: ActivityStatus.RUNNING })}</Radio.Button>
          <Radio.Button value={ActivityStatus.STOPPED}>{formatMessage({ id: ActivityStatus.STOPPED })}</Radio.Button>
        </Radio.Group>
      </FormItem>
      <FormItem
        label={formatMessage({ id: 'TYPE_ID' })}
        name="typeId"
      >
        <TreeSelect
          className={style.select}
          filterTreeNode={filterTreeNodeCallback}
          loading={activitiesTypesLoading}
          showSearch
          showCheckedStrategy={SHOW_PARENT}
          onChange={onActivitySelectChangeHandler}
          value={activityType}
          treeData={getActivityTypeOptions(activityTypes)}
          placeholder={formatMessage({ id: 'SELECT_ACTIVITY' })}
        />
      </FormItem>
      <FormItem
        style={{
          display: isStartFieldDisabled ? "none" : "block"
        }}
        label={formatMessage({ id: 'START' })}
        name="start"
      >
        <DatePicker
          disabled={isStartFieldDisabled}
          format={DATE_TIME_FORMAT}
          showTime
        />
      </FormItem>
      <FormItem
        label={formatMessage({ id: 'COMMENT' })}
        name="comment"
      >
        <Input />
      </FormItem>
      <FormItem
        label={formatMessage({ id: 'TAGS' })}
        name="tags"
      >
        <Select
          allowClear
          showSearch
          mode="tags"
          className={style.select}
          value={activityTags}
          onChange={onTagsSelectChangeHandler}
          options={availableActivityTags?.map(tag => ({ value: tag, label: tag }))}
          loading={activitiesTypesLoading}
          onSearch={onSearchCallback}
          placeholder={formatMessage({ id: 'SELECT_TAGS' })}
        />
      </FormItem>
      <FormItem
        label={formatMessage({ id: 'DURATION' })}
        name="duration"
        hidden
      >
        <Input type="number" disabled/>
      </FormItem>
      <Intervals
        activity={activity}
        intervals={intervals}
        form={form}
        setIntervals={setIntervals} />
    </Form>
  )
}

export default EditActivityForm;
