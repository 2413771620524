import {Col, List, Modal, Row, Tag} from 'antd';
import React, {FC} from "react";
import dayjs from "dayjs";
import { useIntl } from "react-intl";

import {DayIntervals, Interval} from "../../../../../model/activity.model";
import { activityStore } from "../../../../../store/activity.store";
import convertDecimalToHexColor from "../../../../../utils/convertDecimalToHexColor";
import { getDuration } from "./helper";
import IntervalPopoverMenu from "./components/IntervalPopoverMenu";
import style from './style.module.css';
import {Dayjs} from "dayjs";

import { DatePicker } from 'antd';
import {ExclamationCircleFilled} from "@ant-design/icons";

const { RangePicker } = DatePicker;

interface IDaysIntervals {
  dayIntervals: DayIntervals
}

const DaysIntervals: FC<IDaysIntervals> = ({ dayIntervals }) => {
  const { formatMessage } = useIntl();
  const activityTypes = activityStore(state => state.activityTypes);
  const setActivityToEdit = activityStore(state => state.setActivityToEdit);
  const getActivityDetails = activityStore(state => state.getActivityDetails);
  const deleteInterval = activityStore(state => state.deleteInterval);
  const rewriteInterval = activityStore(state => state.rewriteInterval);

  return (
    <List.Item  key={dayIntervals.title}>
      <List
        header={dayIntervals.title}
        className={style.DaysIntervals}
        dataSource={dayIntervals.intervals}
        renderItem={interval => {
          const activityType = activityTypes?.find(type => type.id === interval.typeId);
          const setActivityToEditCallback = () => getActivityDetails(interval.activityId, setActivityToEdit);
          const deleteCallback = () => deleteInterval(interval);

          // @ts-ignore
          const handleChangeInterval = (range: RangeValue<Dayjs>) => {
            const newInterval: Interval = {
              ...interval,
              start: range[0].toISOString(),
              finish: range[1].toISOString()
            }

            void rewriteInterval(newInterval);
          }

          return (
            <List.Item key={interval.id} style={{padding: '6px 0'}}>
              <Row justify='space-between' className={style.DaysIntervalContent}>
                <Col>
                  <Row className={style.InfoRow}>
                    <Col className={style.IntervalLabel}>
                      <div
                        style={{
                          background: convertDecimalToHexColor(activityType?.color || 0)
                        }}
                        className={style.ActivityTypesFilterLabelColor}
                      />
                    </Col>
                    <Col className={style.IntervalLabel}>{activityType?.name}</Col>
                  </Row>
                </Col>
                <Col flex="auto">
                  <Row justify="end">
                    {interval.tags.map(tag => <Tag key={tag} color="purple">{tag}</Tag>)}
                  </Row>
                </Col>
                <Col flex="230px" >
                  <Row className={style.ActionRow}>
                    <Col>
                      <div className={style.IntervalRangeLabel}>
                        <RangePicker
                          separator="-"
                          suffixIcon={null}
                          variant="filled"
                          value={[dayjs(interval.start), dayjs(interval.finish)]}
                          showTime={{ format: 'HH:mm' }}
                          format="HH:mm"
                          allowClear={false}
                          onChange={handleChangeInterval}
                        />
                      </div>
                    </Col>
                    <Col><div className={style.DurationLabel}>{getDuration(interval)}</div></Col>

                    <IntervalPopoverMenu
                      setActivityToEdit={setActivityToEditCallback}
                      setIntervalToDelete={() => {
                        Modal.confirm({
                          icon: <ExclamationCircleFilled />,
                          okType: 'danger',
                          okText: formatMessage({ id: 'DELETE' }),
                          title: formatMessage({ id: 'DELETE_INTERVAL' }),
                          content: formatMessage({ id: 'DELETE_INTERVAL_CONFIRM' }),
                          onOk: deleteCallback
                        });
                      }}
                    />
                  </Row>
                </Col>
              </Row>
            </List.Item>
          )
        }} />
    </List.Item>
  )
}

export default DaysIntervals;
