import {AxiosError} from "axios";
import { create } from 'zustand';

import {InitialRestoreState, RestoreState} from "../model/auth.model";
import {AuthService} from "../api/auth.service";

const initialState: InitialRestoreState = {
  message: null,
  loading: false,
  error: null
};

export const restoreStore = create<RestoreState>((set, get) => ({
  ...initialState,

  restore: async (username: string) => {
    try {
      set({
        loading: true
      })

      const restoreRes = await AuthService.restore(username);

      // onSuccess(restoreRes.data);

      set({
        loading: false,
        error: null,
        message: restoreRes.data.message
      })
    } catch (err) {
      const error = err as AxiosError<Error>
      set({
        loading: false,
        error: error,
        message: null
      })
    }
  },
}));
