import React from "react";
import {Spin} from "antd";
import style from './style.module.css';

export const LoadingPage = () => {
  return (
    <div className={style.LoadingPageContainer}>
      <Spin size="large" />
    </div>
  )
}

export default LoadingPage;
