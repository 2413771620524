import React, {FC} from "react";
import {useIntl} from "react-intl";
import style from './style.module.css';
import {Dropdown, MenuProps, Space} from "antd";
import {DeleteOutlined, EditOutlined, MoreOutlined} from "@ant-design/icons";

interface IIntervalPopoverMenu {
  setIntervalToDelete: () => void
  setActivityToEdit: () => void
}

const IntervalPopoverMenu: FC<IIntervalPopoverMenu> = ({ setIntervalToDelete, setActivityToEdit }) => {
  const { formatMessage } = useIntl();

  const items: MenuProps['items'] = [{
    label: formatMessage({ id: 'EDIT' }),
    onClick: setActivityToEdit,
    key: 'edit',
    icon: <EditOutlined/>
  },
    {
      label: formatMessage({ id: 'DELETE' }),
      onClick: setIntervalToDelete,
      key: 'delete',
      icon: <DeleteOutlined/>
    }
  ];

  return (
    <Dropdown menu={{ items }}  trigger={['click']}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <MoreOutlined
            className={style.MenuIcon}/>
        </Space>
      </a>
    </Dropdown>
  );
};

export default IntervalPopoverMenu;
