import React, {FC, useEffect, useRef, useState} from 'react';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {TIMER_FORMAT} from "../../constant/format";

dayjs.extend(duration)

interface TimeProps {
  start: Date;
}

const Timer: FC<TimeProps> = ({ start }) => {
  const refTimer = useRef<NodeJS.Timer>();

  const [, forceRender] = useState({});

  useEffect(() => {
    refTimer.current = setInterval(() => {
      forceRender({});
    }, 1000);

    return () => {
      if (refTimer.current) {
        clearInterval(refTimer.current);
      }
    };
  }, []);

  return <span>{dayjs.duration(new Date().getTime() - start.getTime()).format(TIMER_FORMAT)}</span>;
};

export default Timer;
