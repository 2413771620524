import axiosInstance from "../config/axiosConfig";
import {AuthConfig, MessageResponseState, User} from "../model/auth.model";
import {CONFIG_LS_KEY} from "../constant/key";

const isAuthConfigPresent = (): boolean => {
  const config = localStorage.getItem(CONFIG_LS_KEY);
  return config !== null;
};

const getCurrentUser = async () => {
  if (!isAuthConfigPresent()) {
    throw new Error('AuthConfig is present in local storage');
  }
  return axiosInstance.get<User>('/api/users/me');
};

export const AuthService = {
  login: async (username: string, password: string) => axiosInstance.post<AuthConfig>(`/auth/jwt`, {
    username,
    password
  }),
  restore: async(username: string) => axiosInstance.post<MessageResponseState>(`/auth/restore`, {
    username
  }),
  changePassword: async(username: string, password: string, token: string) =>
    axiosInstance.post<MessageResponseState>(`/auth/change-password`, {
      username, password, token
    }),
  getCurrentUser,
  getTimezones: async () => axiosInstance.get<string[]>('/api/users/timezones'),
  editUser: async (user: User) => axiosInstance.put<User>('/api/users/me', user)
};
