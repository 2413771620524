import React from 'react';
import {Link, useLocation} from "react-router-dom";
import type { MenuProps } from 'antd';
import { Menu, Layout } from 'antd';

import {
  PieChartOutlined,
  LogoutOutlined, SettingOutlined
} from '@ant-design/icons';
import style from './style.module.css'
import {authStore} from "../../../../store/auth.store";
import {reports, settings} from "../../../../config/router/authorizedRouter";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('', '/reports', <Link to={reports}><PieChartOutlined /></Link>),
  getItem('', '/settings', <Link to={settings}><SettingOutlined /></Link>)
  // , getItem('', '/signout', <Link to={settings}><LogoutOutlined /></Link>)
]

const { Sider } = Layout;

const SideMenu: React.FC = () => {
  const location = useLocation();
  const logOutCallback = authStore(state => state.logOut)

  return (
      <Sider
        breakpoint="lg"
        style={{background: '#2d1b37' }}
             // style={siderStyle}
             collapsedWidth="0"
             width="60"
      >
      <Menu
        className={style.SideMenu}
        selectedKeys={[location.pathname]}
        defaultSelectedKeys={['reports']}
        inlineCollapsed={false}
        mode="inline"
        theme="dark"
        items={items}
      />
      <LogoutOutlined onClick={logOutCallback} className={style.LogOut}/>
      </Sider>
    // </div>
  );
};

export default SideMenu;
