import { Card } from "antd";
import { FC } from "react";
import style from './style.module.css';

interface IPageCard {
  title: React.ReactNode
  children: React.ReactNode
}

const PageCard: FC<IPageCard> = ({ title, children }) => {
  return <Card className={style.card} title={title}>
    {children}
  </Card>
}

export default PageCard;
