import {Button, List} from 'antd';
import React, {FC} from "react";
import {useIntl} from "react-intl";

import {activityStore} from "../../../../../store/activity.store";
import DaysIntervals from "../DaysIntervals/DaysIntervals";
import uuid from "react-uuid";

const HistoryList: FC = () => {
  const { formatMessage } = useIntl();

  const daysIntervals = activityStore(state => state.daysIntervals || []);
  const getIntervalsStartPageLoading = activityStore(state => state.loading.getIntervalsStartPage);
  const getNextIntervalsPageLoading = activityStore(state => state.loading.getNextIntervalsPage);
  const isLast = activityStore(state => state.intervalsPaginationInfo.last);
  const getNextIntervalsStartPage = activityStore(state => state.getNextIntervalsPage);


  return <>
    <List
      loading={getIntervalsStartPageLoading}
      dataSource={daysIntervals}
      rowKey={() => uuid()}
      // rowKey={ dayIntervals => dayIntervals.title }
      renderItem={dayIntervals =>
        <DaysIntervals  key={dayIntervals.title} dayIntervals={dayIntervals}/>
      }
    />
    {!isLast && !!daysIntervals?.length && (
      <Button
        loading={getNextIntervalsPageLoading}
        onClick={getNextIntervalsStartPage}
      >
        {formatMessage({ id: 'LOAD_MORE'})}
      </Button>)
    }

  </>
}

export default HistoryList;
