import axiosInstance from "../config/axiosConfig";
import {
  Activity,
  ActivityType,
  GetPagination,
  GetIntervalsParams,
  GetIntervalsStatisticParams,
  Interval,
  IntervalsResponse,
  Statistics,
  TagsResponse,
  GetTagsParams,
  CreateIntervalFilterPayload,
  IntervalFilter
} from "../model/activity.model";

export const ActivityService = {
  getActivityTags: async (params: GetPagination & GetTagsParams) => axiosInstance.get<TagsResponse>('api/tags', { params }),
  getActivityTypes: async () => axiosInstance.get<ActivityType[]>('api/types'),
  getIntervals: async (params: GetIntervalsParams, pagination: GetPagination) => axiosInstance.post<IntervalsResponse>('api/intervals', params, { params: pagination }),
  getIntervalsFilters: async () => axiosInstance.get('api/filters'),
  createFilter: async (filter: CreateIntervalFilterPayload) => axiosInstance.post('api/filters', filter),
  getStatistics: async (params: GetIntervalsStatisticParams) => axiosInstance.post<Statistics>('api/statistics', params),
  getActivities: async () => axiosInstance.get<{ activities: Activity[] }>('api/activities'),
  getActivity: async (activityId: string) => axiosInstance.get<Activity>(`api/activities/${activityId}`),
  startActivity: async (typeId: string, startTime: number) => axiosInstance.post(`api/activities/start/${typeId}?time=${startTime}`),
  stopActivity: async (typeId: string, stopTime: number) => axiosInstance.post(`api/activities/stop/${typeId}?time=${stopTime}`),
  pauseActivity: async (typeId: string, pauseTime: number) => axiosInstance.post(`api/activities/pause/${typeId}?time=${pauseTime}`),
  resumeActivity: async (typeId: string, resumeTime: number) => axiosInstance.post(`api/activities/resume/${typeId}?time=${resumeTime}`),
  rewriteInterval: async (interval: Interval) => axiosInstance.put(`api/activities/${interval.activityId}/intervals/${interval.id}`, interval),
  rewriteActivity: async (activity: Activity) => axiosInstance.put(`api/activities/${activity.id}`, activity),
  createActivity: async (activity: Partial<Activity>) => axiosInstance.post(`api/activities`, activity),
  deleteInterval: async (interval: Interval) => axiosInstance.delete(`api/activities/${interval.activityId}/intervals/${interval.id}`),
  deleteFilter: async (id: string) => axiosInstance.delete(`api/filters/${id}`),
  updateFilter: async (filter: IntervalFilter) => axiosInstance.put(`api/filters/${filter.id}`, filter),
  receiveExport: async (
    data: {
      from: string;
      to: string;
      timezone: string;
    }
  )=> axiosInstance.post<{ csv: string }>("/api/export", data),
};
