import React, {useEffect} from "react";
import {activityStore} from "../store/activity.store";
import Pusher from 'pusher-js';
import {timeLoggerBaseUrl} from "../constant/url";

interface PusherComponentProps {
  storageConfig: string | null | undefined
}

const APP_KEY = '46a46f5bbb9f34b4624f';
const CLUSTER = 'mt1';

const PusherComponent :React.FC<PusherComponentProps> = ( { storageConfig }) => {
  const refreshData = activityStore(state => state.refreshData);

  useEffect(() => {
    let jwt = "";
    let device = "";
    if (typeof storageConfig === "string") {
      jwt = JSON.parse(storageConfig).token;
      device = JSON.parse(storageConfig).deviceId;
    }

    const pusher = new Pusher(APP_KEY, {
      cluster: CLUSTER,
      authEndpoint: `${timeLoggerBaseUrl}/api/pusher/authenticate`,
      auth: {
        headers: {
          // Assuming you have a method to get the user token
          Authorization: `Bearer ${jwt}`,
        },
      },
    });

    const channel = pusher.subscribe("private-" + device);

    const handleUpdate = () => {
      // Call the refreshData function from the store
      refreshData();
    };

    channel.bind('update', handleUpdate);

    // Cleanup function to unsubscribe and disconnect Pusher
    return () => {
      channel.unbind('update', handleUpdate);
      pusher.unsubscribe("private-" + device);
      pusher.disconnect();
    };
  }, [refreshData, storageConfig]);

  return null;
};

export default PusherComponent;
