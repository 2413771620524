import {Button, Popconfirm, Select} from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import {useIntl} from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";

import { activityStore } from "../../../../../store/activity.store";
import style from "./style.module.css";


const FiltersSelect = () => {
  const { formatMessage } = useIntl();
  const intervalFilters = activityStore(state => state.intervalFilters);
  const filtersConfiguration = activityStore(state => state.filtersConfiguration);
  const setFiltersConfiguration = activityStore(state => state.setFiltersConfiguration);
  const getIntervalsFilters = activityStore(state => state.getIntervalsFilters);
  const setIntervalsParams = activityStore(state => state.setIntervalsParams);
  const deleteFilter = activityStore(state => state.deleteFilter);
  const getIntervalsFiltersLoading = activityStore(state => state.loading.getIntervalsFilters);

  const chosenFilter = useMemo(() =>
    intervalFilters?.find(filter => filter.name === filtersConfiguration),
    [intervalFilters, filtersConfiguration]
  );

  const clearFilterSelect = useCallback(() => {
    setFiltersConfiguration(null);
    setIntervalsParams({ tags: [], types: [] });
  }, [setFiltersConfiguration]);

  const onFilterSelectChangeHandlerCallback = useCallback((value: string) => {
    const filtersToSet = intervalFilters?.find(filter => filter.name === value);

    if (filtersToSet) {
      setFiltersConfiguration(value);
      setIntervalsParams({ tags: filtersToSet.tags, types: filtersToSet.types });
    }
  }, [intervalFilters, setIntervalsParams]);

  const confirmDeleteFiltersCallback = useCallback(() => {
    if (chosenFilter) {
      void deleteFilter(chosenFilter.id)
      setIntervalsParams({ tags: [], types: [] });
      setFiltersConfiguration(null);
    }
  }, [chosenFilter, filtersConfiguration]);

  useEffect(() => {
    void getIntervalsFilters();
  }, []);

  return <>
      <Select
        onClear={clearFilterSelect}
        allowClear
        showSearch
        className={style.FiltersSelect}
        onChange={onFilterSelectChangeHandlerCallback}
        value={filtersConfiguration}
        options={intervalFilters?.map(filter => ({
          value: filter.name, label: filter.name, key: filter.id, id: filter.id
        }))}
        loading={getIntervalsFiltersLoading}
        placeholder={formatMessage({ id: 'SELECT_FILTER' })}
      />
      {filtersConfiguration && (
        <Popconfirm
          title={formatMessage({ id: 'CONFIRM_FILTER_DELETE' })}
          onConfirm={confirmDeleteFiltersCallback}
        >
          <Button>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      )}
  </>
};

export default FiltersSelect;
