import {Interval} from "../../../../../model/activity.model";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const getDuration = (interval: Interval) => {
  const duration = dayjs.duration(dayjs(interval.finish).diff(dayjs(interval.start)));
  if (duration.days() > 0) {
    return Math.floor(duration.asHours()) + duration.format(":mm")
  }
  return duration.format("HH:mm");
}
