import React, {useCallback} from "react";
import {useIntl} from "react-intl";

import {activityStore} from "../../../../../store/activity.store";
import CustomTreeSelect from "../../../../CustomFilterSelect/CustomFilterSelect";
import {getActivityTypeOptions} from "./helper";
import style from './style.module.css';

const ActivityTypeFilter = () => {
  const { formatMessage } = useIntl();
  const activityTypes = activityStore(state => state.activityTypes || []);
  const { types: paramsTypes } = activityStore(state => state.intervalsParams);
  const loading = activityStore(state => state.loading.getActivityTypes);
  const setIntervalsParams = activityStore(state => state.setIntervalsParams);

  const onChange = useCallback((types: string[]) => {
    setIntervalsParams({ types })
  }, [setIntervalsParams]);

  const filterTreeNodeCallback = useCallback((inputValue: string, treeNode: any) => !inputValue
    ? true
    : treeNode.name.toLowerCase().includes(inputValue.toLowerCase())
    , []);

  return (
    <CustomTreeSelect
      className={style.ActivityTypesFilter}
      filterTreeNode={filterTreeNodeCallback}
      loading={loading}
      allowClear
      showSearch
      onChange={onChange}
      value={paramsTypes}
      treeData={getActivityTypeOptions(activityTypes, undefined)}
      placeholder={formatMessage({ id: 'SELECT_TYPES' })}
      label={formatMessage({ id: 'TYPES' })}
    />
  )
}

export default ActivityTypeFilter;
