import axios from 'axios';
import { authStore } from "../store/auth.store";
import { timeLoggerBaseUrl } from "../constant/url";

const LONG_RUNNING_REQUEST_TIMEOUT = 60000; // 60s

const axiosInstance = axios.create({
  timeout: LONG_RUNNING_REQUEST_TIMEOUT, // for development mode set 0
  transitional: {
    clarifyTimeoutError: true
  },
  baseURL: timeLoggerBaseUrl
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      authStore.setState({ user: null });
    }
    return Promise.reject(error);
  });

export const setJWT = (token: string) => axiosInstance.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
});

export const clearJWT = () => axiosInstance.interceptors.request.use((config) => {
  delete config.headers["Authorization"];
  return config;
});

export default axiosInstance;
