import {StoreApi, UseBoundStore} from "zustand";
import {AxiosError} from "axios";

interface IZustandState {
  [fields: string]: any
  loading: Record<string, boolean>
  error: Record<string, null | AxiosError<Error>>
}

type useSimpleFetchMethods = [() => void, (fieldName?: string, fieldData?: unknown) => void, (error: AxiosError<Error>) => void]

export const getSimpleFetchMethods = (state:  UseBoundStore<StoreApi<IZustandState>>, methodName: string): useSimpleFetchMethods => {
  const startLoading = () => {
    state.setState({
      loading: {
        ...state.getState().loading,
        [methodName]: true
      }
    });
  };

  const endLoadingWithSuccess = (fieldName?: string, fieldData?: unknown) => {
    const objectToSet: Partial<IZustandState> = {
      loading: {
        ...state.getState().loading,
        [methodName]: false
      },
      error: {
        ...state.getState().error,
        [methodName]: null
      }
    };

    if (fieldName) {
      objectToSet[fieldName] = fieldData;
    }

    state.setState(objectToSet);
  };

  const endLoadingWithError = (error: AxiosError<Error>) => {
    state.setState({
      loading: {
        ...state.getState().loading,
        [methodName]: false
      },
      error: {
        ...state.getState().error,
        [methodName]: error
      }
    });
  };

  return [startLoading, endLoadingWithSuccess, endLoadingWithError];
};

type usePaginationFetchMethods = [(objectToSet: Record<string, any>) => void, (objectToSet: Record<string, any>, fieldName?: string, fieldData?: unknown) => void, (error: AxiosError<Error>) => void]

export const getPaginationFetchMethods = (state:  UseBoundStore<StoreApi<IZustandState>>, methodName: string): usePaginationFetchMethods => {
  const startLoading = (fieldsToSet: Record<string, any>) => {
    state.setState({
      loading: {
        ...state.getState().loading,
        [methodName]: true
      },
      ...fieldsToSet,
    });
  };

  const endLoadingWithSuccess = (fieldsToSet: Record<string, any>, fieldName?: string, fieldData?: unknown) => {
    const objectToSet: Partial<IZustandState> = {
      loading: {
        ...state.getState().loading,
        [methodName]: false
      },
      error: {
        ...state.getState().error,
        [methodName]: null
      },
      ...fieldsToSet
    };

    if (fieldName) {
      objectToSet[fieldName] = fieldData;
    }

    state.setState(objectToSet);
  };

  const endLoadingWithError = (error: AxiosError<Error>) => {
    state.setState({
      loading: {
        ...state.getState().loading,
        [methodName]: false
      },
      error: {
        ...state.getState().error,
        [methodName]: error
      }
    });
  };

  return [startLoading, endLoadingWithSuccess, endLoadingWithError];
};
