import React, {useCallback, useEffect} from "react";
import {Card, Form, Input, Button, Alert, Row} from "antd";
import {Link, useParams} from 'react-router-dom';
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {login, restore} from "../../config/router/unauthorizedRouter";
import {useIntl} from "react-intl";
import s from "./style.module.css";
import {changePasswordStore} from "../../store/change_password.store";
import {restoreStore} from "../../store/restore.store";

// Define a type for the route parameters
interface RouteParams {
  username: string;
  token: string;
}

const ChangePasswordPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const [form] = Form.useForm();
  const changePassword = changePasswordStore(state => state.changePassword);
  const error = changePasswordStore(state => state.error);
  const message = changePasswordStore(state => state.message);
  const loading = changePasswordStore(state => state.loading);

  const { username, token } = useParams<RouteParams>();

  useEffect(() => {
    // Set initial values if username is provided
    form.setFieldsValue({ username });
  }, [username, form]);

  const onFinishCallback = useCallback(() => {
      return changePassword(username, form.getFieldValue('password'), token)
    },
    [form]
  );

  return (
    <Card className={s.ChangePasswordPage}>
      <Form form={form} onFinish={onFinishCallback}>
        <Form.Item
          name="username" initialValue={{ username }}
          rules={[{ required: true, message: formatMessage({ id: 'ENTER_USERNAME' }) }]}
        >
          <Input
            placeholder={username}
            value={username}
            disabled
            prefix={<UserOutlined />}
          />
        </Form.Item>

        <Form.Item
          name="password"

          rules={[{ required: true, message: formatMessage({ id: 'ENTER_PASSWORD' }) }]}
        >
          <Input.Password
            placeholder={formatMessage({ id: 'PASSWORD' })}
            prefix={<LockOutlined />}
          />
        </Form.Item>

        <Form.Item
          name="repeat_password"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'REPEAT_PASSWORD' }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The new password that you entered do not match!'));
              },
            }),
          ]}
          // rules={[{ required: true, message: formatMessage({ id: 'REPEAT_PASSWORD' }) }]}
        >
          <Input.Password
            placeholder={formatMessage({ id: 'REPEAT_PASSWORD' })}
            prefix={<LockOutlined />}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            {formatMessage({ id: 'CHANGE_PASSWORD' })}
          </Button>
        </Form.Item>

        {message && (
          <Alert message={message} type="info" closable/>
        )}

        {error && (
          <Alert
            message={error.message}
            type="error" closable
          />
        )}

        <Row justify="end">
          <Button type="link" htmlType="button">
            <Link to={login}>{formatMessage({ id: 'SIGN_IN' })}</Link>
          </Button>
        </Row>
      </Form>
    </Card>
  )
};

export default ChangePasswordPage;
