import React from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import {activityStore} from "../../../../store/activity.store";
import style from "./style.module.css";
import {getCurrentActivities} from "./helper";
import CurrentActivity from "./components/CurrentActivity";
import {Dropdown} from "antd";
import {useIntl} from "react-intl";

dayjs.extend(duration);

const CurrentActivitiesList = () => {
  const activities = activityStore(state => state.activities);
  const activityTypes = activityStore(state => state.activityTypes);
  const { formatMessage } = useIntl();

  if (!activities || !activityTypes) {
    return null
  }

  const [firstActivity, ...restActivities] = getCurrentActivities(activities);

  return (
    <div className={style.activityList}>
      {firstActivity &&
        <div>
        <CurrentActivity activityTypes={activityTypes} activity={firstActivity}/>
          {
            !!restActivities?.length && (
              restActivities.length > 1 ?
              <div>
                {
                  <Dropdown menu={{
                      items: restActivities.map(activity => ({
                        key: activity.id,
                        label: (
                          <CurrentActivity
                            compact
                            activityTypes={activityTypes}
                            activity={activity}
                          />
                        )
                      }))
                    }}>
                      <span className={style.more}>{`+${restActivities.length} ${formatMessage({ id: 'ACTIVITY' })}`}</span>
                    </Dropdown>
                }
              </div>
                :
                <CurrentActivity compact activityTypes={activityTypes} activity={restActivities[0]}/>
            )
          }
        </div>
      }
    </div>
  )
}

export default CurrentActivitiesList;
