import {ChartOptions} from 'chart.js';
import {convertHoursToMilliseconds, convertSecondsToHours, formattedDuration} from "../../../../../utils/dateTime";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {Statistics} from "../../../../../model/activity.model";
import convertDecimalToHexColor from "../../../../../utils/convertDecimalToHexColor";
import dayjs from "dayjs";

dayjs.extend(customParseFormat);

type MakeSummaryChartDataType = {
  labels: string[];
  datasets: Array<DatasetType>;
}

type DatasetDataType = Array<number | null>;

type DatasetType = {
  label: string;
  data: DatasetDataType;
  backgroundColor: string;
  borderRadius: number;
}

const makeSummaryChartLabels = (statsData: Statistics): string[] => {
  return statsData?.periods.map(period => {
    return period.title
    /*
    const parsedDate = dayjs(period.title, 'DD.MM.YYYY');

    if (!parsedDate.isValid()) {
      throw new Error('Invalid date string');
    }

    return parsedDate.format('MMM D');
     */
  })
}

const makeDatasetData = (typeId: string, statsData: Statistics, grouped: Boolean): DatasetDataType => {
  return statsData?.periods.map(period => {
    const statistics = grouped ? period.groupedStatistics : period.statistics;
    const  durationSeconds = statistics.find(stat =>
      stat.types.find(type => type === typeId)
    )?.duration  || null;

    return durationSeconds
      ? convertSecondsToHours(durationSeconds)
      : 0;
  })
}

const makeSummaryChartDatasets = (statsData: Statistics, grouped: Boolean): Array<DatasetType> => {
  let typeIdToDuration = new Map<string, number>()

  const statistics = grouped ? statsData?.total.groupedStatistics : statsData?.total.statistics;
  statistics.forEach(stats => {
      stats.types.forEach(typeId => {
        if (!typeIdToDuration.has(typeId)) {
          typeIdToDuration.set(typeId, stats.duration)
        } else {
          const duration = typeIdToDuration.get(typeId)
          if (duration) {
            typeIdToDuration.set(typeId, duration + stats.duration)
          }
        }
      })
    }
  )
  let datasets = new Array<DatasetType>()
  // @ts-ignore
  new Map([...typeIdToDuration].sort((a, b) => {
    if (a.duration > b.duration) return -1;
    if (a.duration < b.duration) return 1;
    return 0;
  })).forEach((value, key) => {
    const type = statsData.types.find(value => value.id === key);

    if (type) {
      const dataset = {
        label: type.name,
        fill: true,
        // cubicInterpolationMode: 'monotone',
        // tension: 0.1,
        data: makeDatasetData(type.id, statsData, grouped),
        backgroundColor: convertDecimalToHexColor(type.color),
        borderRadius: 4,
        skipNull: true,
      }
      datasets.push(dataset)
    }
  });

  return datasets;
}

export const makeSummaryChartData = (statsData: Statistics, grouped: Boolean): MakeSummaryChartDataType => {
  return {
    labels: makeSummaryChartLabels(statsData),
    datasets: makeSummaryChartDatasets(statsData, grouped),
  };
}

export const makeSummaryChartOptions = (animated: Boolean): ChartOptions<'bar'> => {
  const defaults = {
    responsive: true,
    animation: animated,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        position: 'average' as const,
        xAlign: 'center' as const,
        yAlign: 'bottom' as const,
        padding: 12,
        boxPadding: 6,
        cornerRadius: 8,
        callbacks: {
          label: (tooltipItem: { dataset: { data: { [x: string]: number; }; label: string; }; dataIndex: string | number; }) => {
            const durationMilliseconds = convertHoursToMilliseconds(tooltipItem.dataset.data[tooltipItem.dataIndex]);
            return `${tooltipItem.dataset.label}: ${formattedDuration(durationMilliseconds)}`;
          },
        }
      },
    },
    interaction: {
      intersect: false,
      mode: 'x',
      filter: function (tooltipItems: { formattedValue: number; }) {
        return Number(tooltipItems.formattedValue) !== 0;
      },
    },
    scales: {
      x: {
        border: {
          display: false
        },
        grid: {
          display: false,
          drawOnChartArea: true,
          drawTicks: true,
        },
        stacked: true,
      },
      y: {
        type: 'linear',
        border: {
          display: false
        },
        grid: {
          color: '#ddd',
        },
        stacked: true,
        min: 0,
      },
    },
  };

  return {
    ...defaults,
    plugins: {
      ...defaults.plugins,
    },
    scales: {
      ...defaults.scales,
      x: {
        ...defaults.scales.x,
      },
      y: {
        ...defaults.scales.y,
      },
    },
  } as unknown as ChartOptions<'bar'>
};

export const makeSummaryChartLineOptions = (animated: Boolean): ChartOptions<'line'> => {

  const defaults = {
    responsive: true,
    animation: animated,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        position: 'average' as const,
        xAlign: 'center' as const,
        yAlign: 'bottom' as const,
        padding: 12,
        boxPadding: 6,
        cornerRadius: 8,
        callbacks: {
          label: (tooltipItem: { dataset: { data: { [x: string]: number; }; label: string; }; dataIndex: string | number; }) => {
            const durationMilliseconds = convertHoursToMilliseconds(tooltipItem.dataset.data[tooltipItem.dataIndex]);
            return `${tooltipItem.dataset.label}: ${formattedDuration(durationMilliseconds)}`;
          },
        }
      },
    },
    interaction: {
      intersect: false,
      mode: 'x',
      filter: function (tooltipItems: { formattedValue: number; }) {
        return Number(tooltipItems.formattedValue) !== 0;
      },
    },
    scales: {
      x: {
        border: {
          display: false
        },
        grid: {
          display: false,
          drawOnChartArea: true,
          drawTicks: true,
        },
        stacked: true,
      },
      y: {
        type: 'linear',
        border: {
          display: false
        },
        grid: {
          color: '#ddd',
        },
        stacked: true,
        min: 0,
      },
    },
  };

  return {
    ...defaults,
    plugins: {
      ...defaults.plugins,
    },
    scales: {
      ...defaults.scales,
      x: {
        ...defaults.scales.x,
      },
      y: {
        ...defaults.scales.y,
      },
    },
  } as unknown as ChartOptions<'line'>
}
