import {DeleteOutlined} from "@ant-design/icons";
import {Button, DatePicker, FormInstance, Popconfirm, Row} from "antd";
import FormItem from "antd/es/form/FormItem";
import dayjs, {Dayjs} from "dayjs";
import React, {FC, useCallback} from 'react'
import {useIntl} from "react-intl";

import {Activity, Interval} from "../../../../../../model/activity.model";
import style from "../style.module.css";
import {DATE_TIME_FORMAT} from "../../../../../../constant/format";

const { RangePicker } = DatePicker;

interface IIntervals {
  intervals: Interval[]
  form: FormInstance
  setIntervals:  React.Dispatch<React.SetStateAction<Interval[]>>
  activity: Activity
}
const Intervals: FC<IIntervals> = ({ intervals, form, setIntervals, activity }) => {
  const { formatMessage } = useIntl();

  const addNewIntervalCallback = useCallback(() => {
    const newInterval = {
      typeId: activity.typeId,
      activityId: activity.id,
      tags: activity.tags,
      start: new Date().toISOString(),
      finish: new Date().toISOString(),
      comment: '',
    } as Interval;

    setIntervals(intervals => {
      const newIntervals = [...intervals, newInterval];
      form.setFieldValue('intervals', newIntervals);

      return newIntervals;
    });
  }, [activity, setIntervals, intervals]);

  return <>
    <FormItem
      label={formatMessage({ id: 'INTERVALS' })}
    >
      {intervals.map((interval: Interval, index) => {
        const onChangeInterval = (values: [Dayjs | null, Dayjs | null] | null) => {
          const intervals = form.getFieldValue('intervals');
          const newIntervals = intervals.map((intervalElement: Interval) => {
            if (intervalElement.id === interval.id && values) {
              return ({ ...intervalElement, start: values[0]?.toISOString(), finish: values[1]?.toISOString() });
            }

            return intervalElement
          });

          setIntervals(newIntervals);
          form.setFieldValue('intervals', newIntervals);
        };

        const deleteCallback = () => {
          setIntervals(intervals => {
            const newIntervals = intervals.filter(intervalObj => intervalObj !== interval);
            form.setFieldValue('intervals', newIntervals);

            return newIntervals;
          });
        };

        return (
          <Row key={interval.id || index} align='middle' className={style.IntervalRow}>
            <RangePicker
              aria-required
              allowClear={false}
              onChange={onChangeInterval}
              value={[
                dayjs(interval.start),
                dayjs(interval.finish)
              ]}
              format={DATE_TIME_FORMAT}
              showTime
            />
            <Popconfirm
              title={formatMessage({ id: 'DELETE_INTERVAL' })}
              description={formatMessage({ id: 'DELETE_INTERVAL_CONFIRM' })}
              okText={formatMessage({ id: 'YES' })}
              cancelText={formatMessage({ id: 'NO' })}
              onConfirm={deleteCallback}
              placement='left'
            >
              <DeleteOutlined className={style.MenuIcon} />
            </Popconfirm>
          </Row>
        )
      })}
      <Button onClick={addNewIntervalCallback}>{formatMessage({ id: 'ADD_INTERVAL' })}</Button>
    </FormItem>
    <FormItem name="intervals"/>
  </>
}

export default Intervals;

