
import {Doughnut} from "react-chartjs-2";
import {getPieChartData, makePieChartOptions, statisticsItemColor, statisticsItemTitle} from "./helper";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { activityStore } from "../../../../../store/activity.store";
import {Col, List, Row} from "antd";
import style from './style.module.css';
import convertDecimalToHexColor from "../../../../../utils/convertDecimalToHexColor";
import React, {useCallback} from "react";
import {formattedDuration} from "../../../../../utils/dateTime";
import uuid from "react-uuid";

ChartJS.register(ArcElement, Tooltip, Legend);

const ActivityPie = () => {
  const statistics = activityStore(state => state.statistics);

  const statisticsParams = activityStore(state => state.statisticsParams);
  const setStatisticsParams = activityStore(state => state.setStatisticsParams);
  const onGroupedChange = useCallback((value: boolean ) => setStatisticsParams({ grouped: value}), [setStatisticsParams]);

  const grouped = statisticsParams.grouped
  const animated = true;

  return (
    statistics && (
      <Row>
        <Col lg={12} sm={24} xs={24}>
          <div className={style.ActivityPie}>
            <Doughnut
              style={{ maxHeight: 300, maxWidth: 300 }}
              data={getPieChartData(
                grouped ? statistics.total.groupedStatistics : statistics.total.statistics,
                statistics.types)}
              options={makePieChartOptions(
                grouped ? statistics.total.groupedStatistics : statistics.total.statistics,
                animated
              )}
            />
          </div>
        </Col>
        <Col lg={12} sm={24} xs={24}>
          <List
            itemLayout="horizontal"
            dataSource={grouped ? statistics.total.groupedStatistics : statistics.total.statistics}
            rowKey={() => uuid()}
            renderItem={(item, index) => (
              <List.Item key={statisticsItemTitle(statistics?.types, item)}>
                <Row justify='space-between' className={style.DaysIntervalContent}>
                  <Row className={style.InfoRow}>
                    <Col className={style.TypeLabel}>
                      <div
                        style={{
                          background: convertDecimalToHexColor(statisticsItemColor(statistics?.types, item))
                        }}
                        className={style.ActivityTypesFilterLabelColor}
                      />
                    </Col>
                    <Col className={style.TypeLabel}>{statisticsItemTitle(statistics?.types, item)}</Col>
                  </Row>
                  <Row justify='end'>
                    <Col><div className="duration">{formattedDuration(item.duration * 1000)}</div></Col>
                  </Row>
                </Row>
              </List.Item>
            )}
          />
        </Col>

      </Row>

    )
  );
}

export default ActivityPie;
