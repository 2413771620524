import React, {FC} from "react";
import {IntlProvider} from "react-intl";

import SideMenu from "./components/SideMenu/SideMenu";
import languages from "../../resources";
import AuthoredHeader from "./components/AuthoredHeader/AuthoredHeader";
import {authStore} from "../../store/auth.store";
import {language} from "../../enum/languages.enum";
import style from "./style.module.css";
import {Layout} from "antd";

interface IAuthoredProvider {
  children: React.ReactNode
}

const AuthoredProvider: FC<IAuthoredProvider> = ({ children }) => {
  const locale = authStore(state => state.user?.language || language.en);

  return (
    <IntlProvider
      messages={languages[locale]}
      locale={language[locale]}
    >

      {/*<div className={style.page}>*/}
      <Layout hasSider>
        <SideMenu/>
        {/*<div className={style.body}>*/}
        <Layout
          style={{background: '#fdf9f7'}}
          // style={{ marginInlineStart: 60 }}
        >
          <AuthoredHeader />
          <div className={style.content}>
            {children}
          </div>
        </Layout>
        {/*</div>*/}
      </Layout>
      {/*</div>*/}
    </IntlProvider>
  )
}

export default AuthoredProvider
